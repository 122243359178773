import React from 'react'
const Service = () => {
  return (
    <div id='services'>
       <div className="container-fluid service-land">
        <h1 className='service-head'>Our Services</h1>
        <p className='service-para'>Passion meets expertise- for unbeatable bike service.</p>
      </div>

      <div class="container text-center justify-content-center mt-5">
        <h2 className='plan-head'>Choose Your <span className='plan-head-clr'>Service Package</span></h2>
        <div class="row align-items-center justify-content-center">
          <div class="col-10 col-lg-4 plan-col mt-5" data-aos="zoom-out-right">
            <h5 className='plan-in-head'>Scooter</h5>
            <p className='plan-in-para'>General Service </p>
            <div className='line'></div>
            <h4 className='plan-price'><i class="bi bi-currency-rupee icon-curr"></i>1199 INR/-</h4>
            <div className='plan-feature'>
              <p ><i class="bi bi-check icon-tool"></i>Engine Oil</p>
              <p ><i class="bi bi-check icon-tool"></i>Air Filters</p>
              <p ><i class="bi bi-check icon-tool"></i>Sub-Air Filters</p>
              <p ><i class="bi bi-check icon-tool"></i>Carbarator Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Break Oil Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Gear Oil</p>
              <p ><i class="bi bi-check icon-tool"></i>All Cable Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Spark-Plug Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Water Wash</p>
              <p ><i class="bi bi-check icon-tool"></i>Polishing</p>
              {/* <button className='plan-btn'>Enquire Now</button> */}
            </div>

          </div>
          <div class="col-10 col-lg-4 plan-col mt-5" data-aos="zoom-out-left">
            <h5 className='plan-in-head'>100 to 150cc Bikes</h5>
            <p className='plan-in-para'>Greneral Services</p>
            <div className='line'></div>
            <h4 className='plan-price'><i class="bi bi-currency-rupee icon-curr"></i>1299 INR/-</h4>
            <div className='plan-feature'>
              <p ><i class="bi bi-check icon-tool"></i>Engine Oil</p>
              <p ><i class="bi bi-check icon-tool"></i>Air Filter</p>
              <p ><i class="bi bi-check icon-tool"></i>Disc Break Oil Topup</p>
              <p ><i class="bi bi-check icon-tool"></i>Clutch Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Polishing</p>
              <p ><i class="bi bi-check icon-tool"></i>Carbarator Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Break Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>All Cable Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Spark-Plug Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Water Wash</p>
              <p ><i class="bi bi-check icon-tool"></i>Chain Adjustment</p>
            </div>  
          </div>
         
       </div>
       <div className="row mt-5 justify-content-center">
       <div class="col-10 col-lg-4 plan-col mt-5" data-aos="zoom-out-right">
            <h5 className='plan-in-head'>150 to  220cc Bikes</h5>
            <p className='plan-in-para'>Greneral Services</p>
            <div className='line'></div>
            <h4 className='plan-price'><i class="bi bi-currency-rupee icon-curr"></i>1499 INR/-</h4>
            <div className='plan-feature'>
            <p ><i class="bi bi-check icon-tool"></i>Engine Oil</p>
              <p ><i class="bi bi-check icon-tool"></i>Air Filter</p>
              <p ><i class="bi bi-check icon-tool"></i>Disc Oil Topup</p>
              <p ><i class="bi bi-check icon-tool"></i>Oil Filter</p>
              <p ><i class="bi bi-check icon-tool"></i>Polishing</p>
              <p ><i class="bi bi-check icon-tool"></i>Carbarator Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Break Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>All Cable Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Spark-Plug Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Water Wash</p>
              <p ><i class="bi bi-check icon-tool"></i>Chain Adjustment</p>
              <p ><i class="bi bi-check icon-tool"></i>Coolent Oil Topup</p>

            </div>   
           </div>
       <div class="col-10 col-lg-4 plan-col mt-5" data-aos="zoom-out-left">
            <h5 className='plan-in-head'>Super Bikes</h5>
            <p className='plan-in-para'>General Services</p>
            <div className='line'></div>
            <h4 className='plan-price'><i class="bi bi-currency-rupee icon-curr"></i>2399 INR/-</h4>
            <div className='plan-feature'>
            <p ><i class="bi bi-check icon-tool"></i>Engine Oil</p>
              <p ><i class="bi bi-check icon-tool"></i>Air Filter</p>
              <p ><i class="bi bi-check icon-tool"></i>Disc Oil Topup</p>
              <p ><i class="bi bi-check icon-tool"></i>Oil Filter</p>
              <p ><i class="bi bi-check icon-tool"></i>Polishing</p>
              <p ><i class="bi bi-check icon-tool"></i>Carbarator Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Break Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>ABS Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>All Cable Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Spark-Plug Checkup</p>
              <p ><i class="bi bi-check icon-tool"></i>Water Wash/Foam Wash</p>
              <p ><i class="bi bi-check icon-tool"></i>Chain Adjustment</p>
              <p ><i class="bi bi-check icon-tool"></i>Coolent Oil Topup</p>
              

            </div>   
           </div>
       </div>
      </div>
    </div>
  )
}

export default Service