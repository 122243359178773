import React, { useState, useEffect } from 'react';
import './Popup.css'

function Popup() {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    // Show the popup after 5 seconds
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    // Clear the timer if the component unmounts or the popup is shown before 5 seconds
    return () => clearTimeout(popupTimer);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App">
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={closePopup}>×</span>
            <div className='head'><h1>Our Service Plans</h1></div>
            <div className='para'>
            <div className='text'><p>General Scooter Services </p> <span className='rate'>Rs 1199</span></div>
            <div className='text'><p>General Bike Services (100 to 150cc) </p><span className='rate'>Rs 1299</span></div>
            <div className='text'><p>General Bike Services (150cc to 220cc) </p><span className='rate'>Rs 1499</span></div>
            <div className='text'><p>General Super Bikes Services</p> <span className='rate'>Rs 2399</span></div>
            </div>
            {/* <buttton className='btn'>View More Plans</buttton> */}

          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;