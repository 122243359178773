import React from 'react'
import './Services.css'
import { FaTools } from "react-icons/fa";
import img1 from './Images/8084277_1240-removebg-preview.png'
// import { Link } from 'react-router-dom';
// import Navbar from '../Navbar/Navbar'
// import Popup from '../Popup/Popup'
// import Landing from '../Landing/Landing'
// import Feature from '../Columnbox/Feature'
// import Aboutus from '../Aboutus/Aboutus'
// import {Ad} from '../Ad/Ad'
// import WhyChooseus from '../Whyus/WhyChooseus'
// import Brand from '../Brand/Brand'

const Services = () => {


  return (
    <div>
    {/* <Navbar/>
     <Popup/>
      <Landing/>
      <Brand/>
      <Feature/>
      <Aboutus/>
      <Ad/>
      <WhyChooseus/> */}
    
    <div id='services'>
      <div className='title'><h1>Our Services</h1><FaTools className='tool' /></div>
      <div className='head'>

        <div className='sub-head'>
          <div className='main1'>
            <div >
              <div className='gif-sec'><h1 className='one'>Bike's Part Repair</h1></div>
              <p>Bike part repair refers to the process of fixing
                or restoring specific components or parts of a
                bicycle that are damaged, worn out, or not
                functioning correctly. It involves identifying
                the issue, assessing the extent of the
                damage and 
                repairs.  </p>

            </div>
            <div >
              <div className='gif-sec'><h1 className='two'>Battery Change</h1></div>

              <p>The process of replacing the battery used in electric bikes or e-bikes. 
                Electric bikes rely on batteries to provide power to the motor,
                 assisting the rider with pedaling or providing full electric propulsion.</p>

            </div>
          </div>
          <img className="engine" src={img1} alt="" srcset="" />
          <div className='main2'>
            <div>
              <h1 className='one'>Bike washing</h1>
              <p >The method of cleaning bicycles using a foam-based cleaning product. It is a popular technique for 
                effectively and efficiently removing dirt, grime and other contaminants from the bike's surfaces.</p>
            </div>
            <div>
              <div className='gif-sec'><h1 className='two'>Bike Wheel Repair</h1></div>
              <p>Repairing wheels and tyres may involve truing (straightening) a bent wheel, 
                replacing broken spokes, adjusting hub bearings, and repairing or replacing punctured or worn-out tires. 
                The objective is to maintain wheel integrity, true alignment and reliable tyre performance.
                </p>

            </div>
          </div>
        </div>
       
      </div>
      <div className='last'>
          <div className='gif-sec last-head'><h1 className='one'>Full bike Analysis</h1></div>
          <p >Bike analysis refers to the process of evaluating and assessing various aspects of
             a bicycle's performance, condition, and fit. It involves a systematic examination of different components, 
             measurements and data to gain insights into the bike's 
            functionality, efficiency and potential areas for improvement.</p>
        </div>
        {/* <div className="s-btn">
         
          <button className='service-btn'>Explore More</button>
        </div> */}
    </div>
    </div>
  )
}

export default Services