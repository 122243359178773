import './App.css';
import '../src/Components/Service/Style.css'
import '../src/Components/Carousel/Carousel.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Service from './Components/Service/Service';

import Getintouch from './Components/Getintouch/Getintouch';
import Footer from './Components/Footer/Footer';
import Testimonial from './Components/Testimonial/Testimonial';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Services from './Components/Services/Services';

import { BrowserRouter , Route, Routes} from 'react-router-dom';
import { Loader } from './Components/Loader/Loader';
import { useState,useEffect } from 'react';
import Landing from './Components/Landing/Landing';


function App() {
  const [loading,setLoading]=useState(true)
  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },3000)
  },[])

  return (
    <div className='app'>
      
     {
        loading?
        <Loader/>:
      
      <div>
      
        <BrowserRouter>
      {/* <Ad/> */}
      {/* <Services/>
      <Service/> */}
      <Routes>
        <Route path='/' element={<Landing/>}></Route>
        <Route path='/explore' element={<Service/>}></Route>
      </Routes>
      <Testimonial/>
      <Getintouch/>
      <Footer/>
      </BrowserRouter>
      </div>
      }
    </div>
  );
}

export default App;
