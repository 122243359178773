import React from "react";
import Marquee from "react-fast-marquee";
import img1 from './Images/p1.png'
// import img2 from './Images/p9.png'
import img3 from './Images/p3.png.jpg'
// import img6 from './Images/Hero_MotoCorp_Logo.svg'
import img4 from './Images/p4.png'
import img5 from './Images/p5.png'
import img6 from './Images/p6.png'
import img7 from './Images/p7.jpg'
import img8 from './Images/p8.jpg'
import img9 from './Images/p9.png'
import img10 from './Images/p10.jpg'

import "./Brand.css";
import { Container } from "react-bootstrap";

function Mfpartners() {
  return (
    <>
      <div className="section-title  ">
        <h3 className="brand">Brands</h3>
      </div>
      <Container data-aos="fade-up">
        <div className="App Mf-p">
          <div className="mb-3">
            <Marquee direction="right" speed={120} delay={2}>
              <div className="image_wrapper mfp">
                <img src={img1} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img3} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img4} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img5} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img6} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img7} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img8} alt="" />
              </div> 
              <div className="image_wrapper mfp">
                <img src={img9} alt="" />
              </div>
              <div className="image_wrapper mfp">
                <img src={img10} alt="" />
              </div>
              

              
            </Marquee>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Mfpartners;