import React from 'react'
import img from './Images/bike.jpg'
import img1 from './Images/part.jpg'
import './Ad.css'
import { TbDiscount2 } from "react-icons/tb";



export const Ad = () => {
    return (
        <div>
            <div className="container ad" data-aos="zoom-out-down">
            <div class="row justify-content-center off-row">
                <div class="col-8 col-sm-5 col-lg-4 off-col ">
                    <h3>Special Offer </h3>
                    <p>We offer exclusive discounts on labour charges for delivery partners!</p>
                    <img className='w-50 h-25 p-off' src={img1} alt="" srcset="" />
                </div>
                <div class="col-10 col-sm-5 col-lg-3 off-col1">
                    <img className="bikeimg" src={img} alt="" srcset="" />
                </div>
            </div>
            </div>
        </div>
  )
}