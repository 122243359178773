import React from 'react'
import './Aboutus.css'
import aboutimg1 from './Images/Aboutus.jpg'
import aboutimg2 from './Images/motorcycle-294034_1280.png'
const Aboutus = () => {
    return (
        <div>
            <div className="container-fluid abt-contain " id='aboutus'>
                <div class="row justify-content-evenly abt-row">
                    <div class=" col-10 col-sm-10 col-lg-5 abt-col1 order-2 order-lg-1">
                        <img className='aboutimg1' src={aboutimg1} alt="" srcset="" />
                    </div>
                    <div class="col-10 col-sm-10 col-lg-5 abt-col2 order- order-lg-2 ">
                        <img className='abt-img2' src={aboutimg2} alt="" srcset="" />
                        <h3 className='abt-head'>About Us</h3>
                        <p className='abt-para'>Professional bike services require a range of specialized tools and
                            equipment to properly inspect, maintain, and repair bicycles. Here
                            are some common tools and equipment used in Gears and Piston
                            multibrand bike services:</p>
                            <ul>
                                <li className='abt-list'>Professional- grade tools</li>
                                <li className='abt-list'>Precision and efficiency</li>
                                <li className='abt-list'>Torque wrenches for accurate tightening</li>
                                <li className='abt-list'>Chain Whip, Cassette Lockering Tool And Tyre Levers</li>
                                <li className='abt-list'>Cable Cutters, Housing Tools and Bottom Bracket Tools</li>
                            </ul>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus