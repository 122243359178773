import React from 'react'
import footlogo from './Images/Gears_and_pistons_logo-removebg-preview.png'
import './Footer.css'
const Footer = () => {
    return (
        <div>
            <div className="container-fluid foot-contain mt-5">
                <div className="foot-content">
                    <div class="container ">
                        <div class="row ">
                            <div class="col-10 col-lg-3 foot-col1">
                                <img className='foot-logo' src={footlogo} alt="" srcset="" />
                                <p className='foot-para'>Our reputation is built on trust and
                                    reliability. We have earned the trust of
                                    countless customers who rely on us for
                                    their bike service needs.
                                    </p>
                                    <a href="https://www.facebook.com/profile.php?id=61555229093427"><i class="bi bi-facebook sm-icon"></i></a>
                                    <a href="https://www.instagram.com/gears_and_piston?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i class="bi bi-instagram sm-icon"></i></a>
                                    <a href="https://wa.me/918903890380"><i class="bi bi-whatsapp sm-icon"></i></a>
                            </div>
                            <div class="col-10 col-lg-3 foot-col2">
                                <h6>Quick Links</h6>
                                <li><a className='ql-foot' href="#">Home</a></li>
                                <li><a className='ql-foot' href="#aboutus">About Us</a></li>
                                <li><a className='ql-foot' href="#services">Services</a></li>
                                
                            </div>
                            <div class="col-10 col-lg-3 foot-col3">
                                <h6>Contact Us</h6>
                                <p className='footdet'><span className='foot-add'>Address :</span> 320, Nanjundapuram Rd, near Parsn Sesh Nestle, opp. HP bunk, 
                                    Indranagar, Parsn Sesh Nestle Campus, Ramanathapuram, 
                                    Coimbatore, Tamil Nadu 641045.
                                </p>
                                <p className='footdet'><span className='foot-add'>Phone No :</span> +91 8903 8903 80</p>
                                <p className='footdet'><span className='foot-add'>Mail ID:</span> gearsandpiston@gmail.com</p>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <p className='text-center copyright'>Copyrights-All Rights Reserved By Gears & Piston</p>
                <p className='text-center copyright'>Powered By <span className='kriya' ><a href="https://kriyareeths.com/"> Kriya & Reeths</a></span> </p>

            </div>
            
        </div>
    )
}

export default Footer