import React from 'react'
import './Feature.css'

const Feature = () => {
    return (
        <div>
            <div class="container text-center feature-contain ">
                <div class="row align-items-center justify-content-center">
                    <div class="col-10 col-md-5 col-lg-3 f-col">
                        <i class="bi bi-award-fill f-icon"></i>
                        <h4 className='f-head'>Quality Service</h4>
                        <p className='f-para'>We believe in using only the best. Our service includes access to a 
                        curated selection of quality bike parts and
                         accessories to enhance your bike's performance, comfort, and style.
                        </p>
                    </div>
                    <div class="col-10 col-md-5 col-lg-3 f-col">
                    <i class="bi bi-people-fill f-icon"></i>
                    <h4 className='f-head'>Expert Workers</h4>
                        <p className='f-para'>Trust our experienced mechanics to handle any repair job, big or small.
                         We use high-quality parts and advanced diagnostics to identify and fix issues
                         promptly, getting you back on the road with confidence.
                        </p>
                    </div>
                    <div class="col-10 col- col-lg-3 f-col">
                    <i class="bi bi-wrench-adjustable-circle f-icon"></i>
                        <h4 className='f-head'>Modern Equipments</h4>
                        <p className='f-para'>We invest in quality tools, ensuring
                            excellent bike service with regularly
                            maintained, calibrated equipment and
                            promoting bike integrity and
                            longevity.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature