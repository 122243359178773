import React from 'react'
import './WhyChooseus.css'
import img1 from './Images/motorcycle-2773424_1280.png'
import img2 from './Images/man-fixing-motorcycle-modern-workshop.jpg'
import img3 from './Images/percentage.png'

const WhyChooseus = () => {
    return (
        <div>
            <div className='text-center'>
                <h2 className='whyus-head'>Why Choose Us?</h2>
            </div>
            <div className="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-10 col-lg-4 whyus-col1">
                        <p className='whyus-para'>Our priority is your satisfaction. We listen to your specific needs and
                            concerns and tailor our services accordingly. We take the time to
                            understand your bike and your riding style to provide personalized
                            recommendations and solutions. Your happiness with our service is our
                            ultimate goal. We understand that your time is valuable. That's why we
                            offer convenient options to make your bike service hassle-free. You can
                            choose to bring your bike to our well-equipped workshop or take
                            advantage of our mobile service, which brings the expertise to your
                            doorstep.</p>
                            <img className='percentage-img' src={img3} alt="" srcset="" />
                    </div>
                    <div class="col-10 col-lg-5 whyus-col2">
                        <img  className='whyus-img2' src={img2} alt="" srcset="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseus