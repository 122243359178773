import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import img1 from "./Images/Gears and pistons logo.jpg";

import { FaTimes } from "react-icons/fa";

import "./Navbar.css";

function Navbar() {
    const [drop, setDrop] = useState(false);
    const [close, setClose] = useState(false);

    function toggleDrop() {
        setDrop(!drop);
        setClose(!close);
    }

    return (
        <div>
            <div className="topbar">
                <div className="main-top1">
                    <div>
                        <p>
                            <IoMdMail className="ic" />
                            <a className="top-mail" href="mailto:gearsandpiston@gmail.com">gearsandpiston@gmail.com</a>
                        </p>
                    </div>
                    <div>
                        <p>
                            <FaPhoneAlt className="ic" />
                            <a className="top-mail" href="tel:8903890380">8903-8903-80</a>
                        </p>
                    </div>
                </div>
                <div className="main-top2">
                    <a href="https://www.facebook.com/profile.php?id=61555229093427"><FaFacebookF className="ic1" /></a>
                    <a className="insta" href="https://www.instagram.com/gears_and_piston?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><AiFillInstagram className="ic1" /></a>
                    <a className="insta" href="https://wa.me/918903890380"><IoLogoWhatsapp className="ic1" /></a>
                </div>
            </div>
            <div className="nav">
                <img className="logo" src={img1} alt="" srcset="" />
                <div className="navigation">
                    <h6><a className="nav-link" href="">Home</a></h6>
                    <h6><a className="nav-link"  href="#aboutus">About Us</a></h6>
                    <h6><a className="nav-link"  href="#services">Services</a></h6>
                    <h6><a className="nav-link" href="#contactus">Contact Us</a></h6>
                </div>
                <button className="ham" onClick={toggleDrop}>
                    {close ? (
                        <FaTimes className="icon" />
                    ) : (
                        <GiHamburgerMenu className="icon" />
                    )}
                </button>
            </div>
            <div
                className={`drop2 {drop ? "active" : ""}`}
            style={{
                backgroundColor: "black",
                position: "absolute",
                width: "100%",
                height: "240px",
                color: "white",
                transition: "all 1.5s", // Adjusted the transition duration
                zIndex: 10,
                top: drop ? "100px" : "-200px",
            }}
      >
            <div className="drop2">
                <div className="content">
                    <div><a href="">Home</a></div>
                    <div><a href="#aboutus">About Us</a></div>
                    <div><a href="#services">Service</a></div>
                    <div><a href="#contactus">Contact Us</a></div>
                </div>
            </div>
        </div>
    </div >
  );
}
export default Navbar;