import React from 'react';
import './Getintouch.css';
import img1 from './Images/motorcycle-3658190_1280.png'

const Getintouch = () => {
    return (
        <div className='getin-contain' id='contactus'>
            <div className="container-fluid mt-5 ">
                <div className="row justify-content-evenly">
                    <div className="col-10 col-lg-4 getin-col1">
                        <h1 className='get-head'>Get In Touch</h1>
                        <h3 className='get-para'>From connection to <span className='span-getin'>TRANSFORMATION</span></h3>

                        <button className='get-btn'><a href="https://wa.me/918903890380">Send Message</a></button>
                        {/* <img className='getin-img' src={img1} alt="" srcset="" /> */}
                    </div>
                    <div className="col-10 col-lg-4 getintouch-col2">
                        <iframe className='map'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.6649208689137!2d76.99745817486016!3d10.98864478917336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8599bccc9756d%3A0xa5055523d576c009!2sGears%20and%20Piston!5e0!3m2!1sen!2sin!4v1708061506957!5m2!1sen!2sin" 
                            width="500"
                            height="400"
                            style={{ border: '7px solid #CD3E0A' }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Getintouch;
