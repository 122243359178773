import React from 'react'
import './Landing.css'
import landimg from './Images/motorcycle-6487984_1280.png'
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import Popup from '../Popup/Popup'
// import Landing from '../Landing/Landing'
import Feature from '../Columnbox/Feature'
import Aboutus from '../Aboutus/Aboutus'
import {Ad} from '../Ad/Ad'
import WhyChooseus from '../Whyus/WhyChooseus'
import Brand from '../Brand/Brand'
import Services from '../Services/Services';



const Landing = () => {
    return (
        <div>
     <Navbar/>
     <Popup/>
    
      
            <div className="container-fluid">
                <div class="row justify-content-evenly land-row">
                    <div class="col-sm-12 col-md-12 col-lg-4 column1 order-2 order-lg-1">
                        <h1 className='land-head'>Your Bike's Best <span className='land-span'><br className='d-none' />Friend!</span></h1>
                        <p className='land-para'>Your satisfaction is our priority. We take the time to listen to your
                         specific needs and concerns, providing personalized recommendations and solutions. We believe in transparent communication and our team is always ready to answer any questions you may have.
                        </p>
                        <Link to="/explore"><button className='land-btn'><a href="https://wa.me/918903890380">Our Service Palns</a></button></Link>
                        <button className='land-btn enquire-btn'><a href="https://wa.me/918903890380">Enquire Now</a></button>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 column2 order-1 order-lg-2">
                        <img className='land-img' src={landimg} alt="" srcset="" />
                    </div>
                </div>
            </div>
            <Brand/>
      <Feature/>
      <Aboutus/>
      <Ad/>
      <WhyChooseus/>
      <Services/>
    
        </div>
    )
}

export default Landing